<template>
  <div class="group"
       ref="group">
    <ul>
      <li style="border-bottom:1px solid #DEDEDE;position:sticky;top:0;z-index:10">
        <span style="font-weight:bold;cursor:default">全部分组</span>
        <div @mousedown.prevent="">
          <Button @click.stop="onClickCreate"
                  style="color:#2D8CF0"
                  type="text">
            <Icon custom="i-icon icon-add"
                  size="16"/>
            添加
          </Button>
        </div>
      </li>
      <draggable :move="onMove"
                 @end="onDragEnd"
                 @update="onChangeSort"
                 animation="300"
                 filter=".forbid"
                 handle=".cursor-move"
                 v-model="list">
        <transition-group>
          <li :class="{current:selTagId===item.id,forbid:!item.groupId}"
              :key="index"
              v-for="(item,index) in list">
            <Icon class="cursor-move m-r-5"
                  custom="i-icon icon-drag"
                  size="20"
                  v-if="item.groupId"/>
            <span @click.stop="onClickItem(item,index)"
                  v-show="!item.isRename">{{item.groupName}} <span
              v-if="item.groupCount">({{item.groupCount}})</span> </span>
            <Input @on-blur="onBlur(item,index)"
                   @on-enter="onBlur(item,index)"
                   @on-focus="onFocus($event)"
                   class="w-full"
                   maxlength="8"
                   ref="input"
                   show-word-limit
                   v-model="item.newName"
                   v-show="item.isRename"></Input>
            <Dropdown @on-click="(key)=>{onClickOperation(key,item,index)}"
                      transfer
                      trigger="click"
                      v-if="item.groupId"
                      v-show="!item.isRename">
              <Icon class="cursor-pointer"
                    custom="i-icon icon-more"
                    size="20"
                    title="操作"/>
              <DropdownMenu slot="list">
                <DropdownItem name="rename">编辑</DropdownItem>
                <DropdownItem name="delete">删除</DropdownItem>
                <DropdownItem name="authority" style="display: none">权限</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
        </transition-group>
      </draggable>

    </ul>
  </div>
</template>

<script>
import GroupCreateComponent from './GroupCreateComponent'
import GroupManageComponent from './GroupManageComponent'
import draggable from 'vuedraggable'

export default {
  components: {GroupCreateComponent, GroupManageComponent, draggable},
  props: {
    datalist: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      list: [],
      selTagId: 0,
      currentIndex: 0,
      isAddStatus: false
    }
  },
  watch: {
    datalist: {
      handler (newVal) {
        this.list = JSON.parse(JSON.stringify(this.datalist))
        let item = {
          groupId: 0,
          groupName: '全部',
          newName: '',
          isRename: false
        }
        this.list.unshift(item)
        if (this.list && this.list.length) {
          if (!this.selTagId) {
            this.selTagId = this.list[0].id
          } else {
            let ids = this.list.filter(res => {
              return res.id === this.selTagId
            })
            if (!(ids && ids.length)) {
              this.selTagId = this.list[0].id
            }
          }
        }
      },
      immediate: true
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 点击新建
    onClickCreate () {
      if (this.isAddStatus) {
        return
      }
      this.isAddStatus = true
      let item = {
        groupId: -1,
        groupName: '',
        newName: '',
        isRename: true
      }
      this.datalist.unshift(item)
      this.onClickRename(item, 1)
      this.$refs.group.scrollTop = 0
    },
    onClickItem (item, index) {
      this.currentIndex = index
      this.selTagId = item.id
      this.$emit('on-click-item', {item, index})
    },
    // 操作
    onClickOperation (key, item, index) {
      switch (key) {
        case 'rename':
          this.onClickRename(item, index)
          break
        case 'delete':
          this.$emit('on-delete', item)
          break
        case 'authority':
          this.$emit('on-privilege', item)
          break
      }
    },
    // 点击重命名
    onClickRename (item, index) {
      item.isRename = true
      this.$nextTick(() => {
        this.$refs.input[index].focus()
      })
    },
    // input聚焦
    onFocus (e) {
      e.target.select()
    },
    // 部门input失焦
    onBlur (item, index) {
      if (item.groupId === -1) {
        this.isAddStatus = false
        this.datalist.splice(0, 1)
        if (item.newName === '') {
          return
        }
        this.$emit('on-click-create', item.newName)
      } else {
        if (item.newName === '') {
          item.isRename = false
          item.newName = item.groupName
          return
        }
        if (item.groupName === item.newName) {
          item.isRename = false
          return
        }
        this.$emit('on-rename', item)
      }
    },
    //禁止拖动
    onMove (e) {
      if (!e.relatedContext.element.groupId) return false
      return true
    },
    onChangeSort () {
      // this.$emit('on-change-sort', this.list)
    },
    onDragEnd (param) {
      let selIndex = param.newIndex
      let oldIndex = param.oldIndex
      this.$emit('on-change-sort', {
        selIndex: selIndex,
        oldIndex: oldIndex,
        list: this.list
      })
    },
  },
}
</script>

<style lang='scss' scoped>
  .group {
    width: 300px;
    height: 100%;
    overflow-y: auto;

    li {
      height: 60px;
      padding: 0 20px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.current {
        background-color: #f3f8fe;

        span {
          color: #2d8cf0;
        }
      }

      span {
        flex: 1;
        font-size: 16px;
        color: #666;
        cursor: pointer;
      }

      ::v-deep .ivu-btn {
        padding: 0;
      }
    }
  }
</style>
