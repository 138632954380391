<template>
  <div @click.stop="visible=true">
    <slot></slot>
    <Modal footer-hide
           title="分组管理"
           v-model="visible"
           width="488">
      <div class="manage">
        <div class="manage-title">拖拽分组排序</div>
        <ul>
          <li>
            <span>未分组</span>
            <span>系统分组</span>
          </li>
          <draggable @update="onChangeSort"
                     animation="300"
                     handle=".cursor-move"
                     v-model="list">
            <transition-group>
              <template>
                <li :key="index"
                    v-for="(item,index) in list"
                    v-if="item.groupId>0">
                  <span v-show="!item.isRename">{{item.groupName}}</span>
                  <Input :ref="'input'+index"
                         @on-blur="onBlur(item)"
                         @on-enter="onBlur(item)"
                         @on-focus="onFocus($event)"
                         class="flex-1"
                         v-model="item.newName"
                         v-show="item.isRename"/>
                  <div class="manage-operation">
                    <Icon @click.stop="onClickRename(item,index)"
                          class="m-l-5 cursor-pointer"
                          custom="i-icon icon-rename"
                          size="16"/>
                    <Icon @click.stop="onClickDelete(item)"
                          class="m-l-5 cursor-pointer"
                          custom="i-icon icon-delete"
                          size="16"/>
                    <Icon @click.stop="onClickPrivilege(item)"
                          class="m-l-5 cursor-pointer"
                          custom="i-icon icon-permission"
                          size="16"/>
                    <Icon class="m-l-5 cursor-move"
                          size="20"
                          type="md-reorder"/>
                  </div>
                </li>
              </template>
            </transition-group>
          </draggable>
        </ul>
      </div>
    </Modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {draggable},
  props: {
    data: Array
  },
  data () {
    return {
      visible: false,
      list: []
    }
  },
  watch: {
    data (newVal) {
      this.list = newVal
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 点击重命名
    onClickRename (item, index) {
      item.isRename = true
      this.$nextTick(() => {
        this.$refs['input' + index][0].focus()
      })
    },
    // input聚焦
    onFocus (e) {
      e.target.select()
    },
    onBlur (item) {
      if (item.newName === '') {
        item.isRename = false
        item.newName = item.groupName
        return
      }
      if (item.groupName === item.newName) {
        item.isRename = false
        return
      }
      this.$emit('on-rename', item)
    },
    onClickDelete (item) {
      this.$emit('on-delete', item)
    },
    onClickPrivilege (item) {
      this.$emit('on-privilege', item)
    },
    onChangeSort () {
      this.$emit('on-change-sort', this.list)
    },
  },
}
</script>

<style lang='scss' scoped>
  .manage {
    .manage-title {
      font-size: 14px;
      color: #999;
    }

    ul {
      li {
        padding: 0 10px;
        height: 44px;
        border-bottom: 1px solid #d8d8d8;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 16px;
        }
      }
    }
  }
</style>
