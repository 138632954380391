<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <div class="topbar-left">
        <Button @click.stop="onClickCreateTag"
                class="newFolder"
                type="primary">
          <Icon size="18"
                type="md-add-circle"/>
          新建标签
        </Button>
        <ButtonGroup class="m-l-30"
                     v-show="selectArr.length>0">
          <Button @click.stop="onClickMergeTag"
                  class="batchBtn"
                  v-show="selectArr.length>1">
            <Icon custom="i-icon icon-merge"
                  size="16"/>
            合并标签
          </Button>
          <Button @click.stop="batchDelete"
                  class="batchBtn">
            <Icon custom="i-icon icon-delete"
                  size="16"/>
            删除
          </Button>
          <Button @click.stop="onClickChangeTagGroup"
                  class="batchBtn"
                  style="display: none">
            <Icon custom="i-icon icon-remove"
                  size="16"/>
            改变分组
          </Button>
        </ButtonGroup>
      </div>
    </div>
    <div class="content">
      <GroupListComponent :datalist="groupArr"
                          @on-change-sort="editGroupSort"
                          @on-click-create="createGroup"
                          @on-click-item="onClickGroupItem"
                          @on-delete="deleteGroup"
                          @on-privilege="onPrivilege"
                          @on-rename="editGroup"></GroupListComponent>
      <div class="list">
        <DiskTableComponent :columns="columns"
                            :data="data"
                            :sortKey="sortKey"
                            :sortType="sortType"
                            @on-selection-change="onSelectionChange"
                            @on-sort-change="onSortChange"
                            ref="tagTable">
          <template slot="operation"
                    slot-scope="{ row,index }">
            <div class="operation">
              <Icon @click.stop="getTagFolderAndFileList(row)"
                    class="m-r-10"
                    custom="i-icon icon-relevance"
                    size="20"
                    title="关联文件"/>
              <Icon @click.stop="onClickEditTag(row)"
                    class="m-r-10"
                    custom="i-icon icon-edit"
                    size="20"
                    title="编辑"/>
              <Icon @click.stop="onClickDelete(row)"
                    custom="i-icon icon-delete"
                    size="20"
                    title="删除"/>
            </div>
          </template>
        </DiskTableComponent>
        <infinite-loading :distance="200"
                          :identifier="manualResetState"
                          @infinite="getTagList"
                          ref="infiniteLoading">
          <div class="total m-t-10"
               slot="no-more">共{{data.length}}项
          </div>
        </infinite-loading>
      </div>
    </div>
    <Modal :title="(tagPopupStatus===1?'新建':tagPopupStatus===2?'修改':'合并')+'标签'"
           footer-hide
           v-model="createTagVisible"
           width="680">
      <div class="createTag">
        <div class="createTag-title"
             v-if="tagPopupStatus===3">将选中的标签合并为一个标签。
        </div>
        <ul class="createTag-form">
          <li class="createTag-li">
            <span>添加标签：</span>
            <div class="createTag-list">
              <template v-for="(item,index) in createTagArr">
                <Tag :closable="tagPopupStatus===1"
                     :color="item.bgc"
                     :key="index"
                     :name="index"
                     @on-close="onDeleteCreateTag"
                     style="height:28px">
                  <input :style="{color:item.color,width:inputWidth(item.name)}"
                         @focus="createTagObj=item"
                         class="createTag-input"
                         maxlength="8"
                         placeholder="标签名称"
                         v-model="item.name"/>
                </Tag>
              </template>
              <Button @click.stop="onClickAddCreateTag"
                      style="width:60px;height:28px;padding:0"
                      v-if="tagPopupStatus===1">
                <Icon size="16"
                      type="md-add-circle"/>
                添加
              </Button>
            </div>
          </li>
          <li class="createTag-li">
            <span>标签分组：</span>
            <Select placeholder="请选择标签分组"
                    style="width:200px"
                    v-model="createTagObj.groupId">
              <template v-for="(item,index) in groupArr">
                <Option :key="index"
                        :value="item.groupId"
                        v-if="item.groupId>-1">{{item.groupName}}
                </Option>
              </template>
            </Select>
          </li>
          <li class="createTag-li">
            <span>文字颜色：</span>
            <ColorPicker v-model="createTagObj.color"/>
          </li>
          <li class="createTag-li">
            <span>标签颜色：</span>
            <ColorPicker v-model="createTagObj.bgc"/>
          </li>
        </ul>
        <div class="createTag-sub">
          <Button @click.stop="onClickCreateConfirm"
                  class="createTag-btn m-r-20"
                  type="primary">保存
          </Button>
          <Button @click.stop="createTagVisible=false"
                  class="createTag-btn">取消
          </Button>
        </div>
      </div>
    </Modal>
    <Modal footer-hide
           title="改变分组"
           v-model="changeTagGroupVisible"
           width="380">
      <div class="changeTagGroup">
        <div class="changeTagGroup-title">将已选中的标签移动到：</div>
        <ul class="changeTagGroup-form">
          <li class="changeTagGroup-li">
            <span>选择分组：</span>
            <Select placeholder="请选择标签分组"
                    style="width:200px"
                    v-model="changeTagGroupId">
              <template v-for="(item,index) in groupArr">
                <Option :key="index"
                        :value="item.groupId"
                        v-if="item.groupId>-1">{{item.groupName}}
                </Option>
              </template>
            </Select>
          </li>
        </ul>
        <div class="changeTagGroup-sub">
          <Button @click.stop="onClickChangeConfirm"
                  class="changeTagGroup-btn m-r-20"
                  type="primary">确定
          </Button>
          <Button @click.stop="changeTagGroupVisible=false"
                  class="changeTagGroup-btn">取消
          </Button>
        </div>
      </div>
    </Modal>
    <Drawer :closable="false"
            :mask="false"
            :mask-closable="false"
            :transfer="false"
            :width="width"
            inner
            scrollables
            v-model="childVisible">
      <div class="childView">
        <div @click.stop="childVisible=false"
             class="childView-close">> 收起
        </div>
        <div class="childView-con">
          <div class="childView-top">
            <div class="childView-left">
              <ButtonGroup class="m-l-30"
                           style="display: none"
              >
                <!--v-show="selectChildArr.length>0"-->
                <Button class="batchBtn" style="display: none">
                  <Icon custom="i-icon icon-share"
                        size="16"/>
                  分享
                </Button>
                <Button class="batchBtn" style="display: none">
                  <Icon custom="i-icon icon-download"
                        size="16"/>
                  下载
                </Button>
                <Button @click.stop="batchDeleteChild"
                        class="batchBtn"
                        style="display: none">
                  <Icon custom="i-icon icon-delete"
                        size="16"/>
                  删除
                </Button>
                <Button @click.stop="onClickChildRename"
                        class="batchBtn"
                        style="display: none"
                >
                  <!--v-show="selectChildArr.length===1"-->
                  <Icon custom="i-icon icon-rename"
                        size="16"/>
                  重命名
                </Button>
                <Button @click.stop="batchMoveChild"
                        class="batchBtn"
                        style="display: none">
                  <Icon custom="i-icon icon-remove"
                        size="16"/>
                  移动到
                </Button>
                <Button @click.stop=""
                        class="batchBtn"
                        style="display: none">
                  <Icon custom="i-icon icon-tags"
                        size="16"/>
                  添加标签
                </Button>
                <Button class="batchBtn" style="display: none">
                  <Icon custom="i-icon icon-permission"
                        size="16"/>
                  权限
                </Button>
              </ButtonGroup>
            </div>
            <div class="childView-right">
              <Icon :color="listShowType===1?'#2D8CF0':'#666'"
                    @click="listShowType = 1"
                    custom="i-icon icon-list1x"
                    size="20"/>
              <Icon :color="listShowType===2?'#2D8CF0':'#666'"
                    @click="listShowType = 2"
                    custom="i-icon icon-view1x"
                    size="20"/>
            </div>
          </div>
          <div class="childView-list">
            <div v-show="listShowType === 1">
              <DiskTableComponent :columns="childColumns"
                                  :data="childData"
                                  :sortKey="childSortKey"
                                  :sortType="childSortType"
                                  @on-rename="onChildRename"
                                  @on-row-dblclick="onRowDblclick"
                                  @on-selection-change="onChildSelectionChange"
                                  @on-sort-change="onSortChildChange"
                                  ref="table">
                <template slot="operation"
                          slot-scope="{ row,index }">
                  <div class="operation">
                    <!--<Icon class="m-r-10"-->
                    <!--color="#2D8CF0"-->
                    <!--custom="i-icon icon-share"-->
                    <!--size="20"-->
                    <!--title="分享"/>-->
                    <!--<Icon class="m-r-10"-->
                    <!--color="#2D8CF0"-->
                    <!--custom="i-icon icon-download"-->
                    <!--size="20"-->
                    <!--title="下载"/>-->
                    <!--<Dropdown @on-click="(key)=>{onClickOperation(key,row,index)}"-->
                    <!--transfer-->
                    <!--trigger="click">-->
                    <!--<Icon color="#2D8CF0"-->
                    <!--custom="i-icon icon-more"-->
                    <!--size="20"-->
                    <!--title="操作"/>-->
                    <!--<DropdownMenu slot="list">-->
                    <!--<DropdownItem name="move">移动到</DropdownItem>-->
                    <!--<DropdownItem name="rename">重命名</DropdownItem>-->
                    <!--<DropdownItem name="delete">删除</DropdownItem>-->
                    <!--<DropdownItem name="authority" style="display: none">权限管理</DropdownItem>-->
                    <!--</DropdownMenu>-->
                    <!--</Dropdown>-->
                  </div>
                </template>
              </DiskTableComponent>
            </div>
            <div v-show="listShowType === 2">
              <DiskGridComponent :data="childData"
                                 :grids="childGrids"
                                 :sortKey="childSortKey"
                                 :sortType="childSortType"
                                 @on-rename="onChildRename"
                                 @on-row-dblclick="onRowDblclick"
                                 @on-selection-change="onChildSelectionChange"
                                 @on-sort-change="onSortChildChange"
                                 ref="grid">
                <div slot="tag-position">

                </div>
              </DiskGridComponent>
            </div>
            <infinite-loading :distance="200"
                              :identifier="manualResetChildState"
                              @infinite="getAllFileList"
                              ref="infiniteLoading">
              <div class="total"
                   slot="no-more">共{{childData.length}}项
              </div>
            </infinite-loading>
          </div>
        </div>
      </div>
    </Drawer>
    <DiskDirectoryComponent :selectArr="selectArr"
                            :title="directoryTitle"
                            @on-click-cancle="cancleSelectFolder"
                            @on-click-confirm="getSelectFolder"
                            v-model="directoryVisible"></DiskDirectoryComponent>
  </div>
</template>

<script>
import {
  createGroup,
  createTag,
  deleteFolderAndFileToRecycle,
  deleteGroup,
  deleteTag,
  editFileName,
  editFolderName,
  editGroup,
  editGroupSort,
  editTag,
  getGroupList,
  getTagFileList,
  getTagFolderList,
  getTagList,
  mergeTag,
  moveFolderAndFile,
  objRelevanceGroup,
} from '../../services/diskapi'
import GroupListComponent from '../../components/disk/GroupListComponent'
import DiskTableComponent from '../../components/disk/DiskTableComponent'
import DiskGridComponent from '../../components/disk/DiskGridComponent'
import DiskDirectoryComponent from '../../components/disk/DiskDirectoryComponent'

export default {
  components: {GroupListComponent, DiskTableComponent, DiskGridComponent, DiskDirectoryComponent},
  data () {
    return {
      search: '',
      width: 0,
      groupArr: [],
      groupId: 0,
      columns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '标签名称',
          key: 'labelName',
          minWidth: 200,
          // sortable: 'custom',
          render: (h, params) => {
            let name = params.row.labelName
            let color = params.row.fontColor
            let bgc = params.row.color
            return h('span', {
              style: {
                display: 'inline-block',
                padding: '0 20px',
                lineHeight: '32px',
                backgroundColor: bgc,
                borderRadius: '4px',
                color: color
              }
            }, name)
          }
        },
        {
          title: '操作',
          slot: 'operation',
          width: 140
        }
      ],
      data: [],
      selectArr: [],
      pageNum: 1,
      pageSize: 20,
      sortKey: 'labelName',
      sortType: 'asc',
      isMore: true,
      manualResetState: false,
      createTagVisible: false,
      createTagObj: {
        groupId: -1,
        name: '',
        color: '',
        bgc: ''
      },
      createTagArr: [],
      tagPopupStatus: 1,// 1新建 2修改 3合并
      changeTagGroupVisible: false,
      changeTagGroupId: 0,
      tagId: 0,
      childVisible: false,
      listShowType: 1,
      childColumns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '文件名称',
          key: 'name',
          minWidth: 200,
          // sortable: 'custom',
          tooltip: true,
          slot: 'name'
        },
        {
          title: '修改时间',
          key: 'editTime',
          width: 170,
          // sortable: 'custom',
        },
        {
          title: '文件大小',
          key: 'size',
          width: 140,
          // sortable: 'custom',
          render: (h, params) => {
            let size = params.row.size
            let text = size ? this.$formatFileSize(size) : '-'
            return h('span', text)
          }
        },
        {
          title: '创建人',
          key: 'userName',
          width: 140,
        },
        // {
        //   title: '权限',
        //   key: '',
        //   width: 120,
        // },
        {
          title: '操作',
          slot: 'operation',
          width: 140
        }
      ],
      childGrids: [
        {
          title: '文件名称',
          key: 'name'
        },
        {
          title: '修改时间',
          key: 'editTime',
        },
        {
          title: '文件大小',
          key: 'size',
        }],
      childData: [],
      selectChildArr: [],
      childPageNum: 1,
      childPageSize: 20,
      isMoreFolder: true,
      isMoreFile: true,
      childSortKey: 'name',
      childSortType: 'asc',
      directoryVisible: false,
      directoryTitle: '移动',
      manualResetChildState: false,
    }
  },
  computed: {
    inputWidth () {
      return function (value) {
        if (value.length <= 4) {
          return '60px'
        } else {
          return value.length * 15 + 'px'
        }
      }
    }
  },
  watch: {
    groupId: {
      handler (newValue) {
        this.resetListData()
      },
      immediate: true
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.width = this.$refs.main.offsetWidth
      this.getGroupList()
    },
    // 重置列表数据
    resetListData () {
      this.pageNum = 1
      this.data = []
      this.selectArr = []
      this.isMore = true
      this.manualResetState = !this.manualResetState
    },
    // 获取分组列表
    getGroupList () {
      getGroupList({
        pageNum: 1,
        pageSize: 10000,
        parentId: 0,
        folderType: 22,
      }).then(res => {
        if (res.code === 0) {
          let arr = res.data.list.map(item => {
            item.isRename = false
            item.groupName = item.name || ''
            item.groupId = item.id
            item.newName = item.name || ''
            item.groupCount = item.documentCount || 0
            return item
          })
          this.groupArr = arr
          if (this.groupArr && this.groupArr.length) {
            if (this.groupId > 0) {
              let item = this.groupArr.filter(chilld => {
                return this.groupId === chilld.id
              })
              if ((item && item.length)) {
                this.groupId = this.groupArr[0].groupId
              }
            } else {
              this.groupId = 0
            }
          } else {
            this.groupId = 0
          }
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 点击分组item
    onClickGroupItem (obj) {
      let item = obj.item
      this.groupId = item.groupId || 0
      // this.resetListData()
    },
    // 新建分组
    createGroup (name) {
      let params = {
        permissionType: 1,
        name: name,
        parentId: 0,
        folderType: 22
      }
      createGroup(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('创建成功')
          this.getGroupList()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 修改分组
    editGroup (item) {
      let params = {
        folderGroupId: 1,
        permissionType: 1,
        name: item.newName,
        userList: []
      }
      editGroup(item.id, params).then(res => {
        item.isRename = false
        if (res.code === 0) {
          item.groupName = item.newName
        } else {
          item.newName = item.groupName
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 删除分组
    deleteGroup (item) {
      this.$Modal.confirm({
        title: '删除',
        content: '<p>确定要删除该分组</p>',
        onOk: () => {
          let params = {
            folderIdList: item.id,
            folderType: 22
          }
          deleteGroup(params).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功!')
              this.getGroupList()
            } else {
              item.newName = item.groupName
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 修改分组排序
    editGroupSort (data) {
      let oldIndex = data.oldIndex
      let newIndex = data.selIndex
      let folderId = data.list[newIndex].id
      if (oldIndex === newIndex) {
        return
      }
      let sort = 0
      if (newIndex > oldIndex) {
        sort = data.list[newIndex - 1].sort
      } else {
        sort = data.list[newIndex + 1].sort
      }
      editGroupSort({folderId, sort}).then(res => {
        if (res.code === 0) {
          this.getGroupList()
        } else {
          this.getGroupList()
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 修改分组权限
    onPrivilege () {
    },
    // 获取分组下的列表
    getTagList ($state) {
      if (!this.isMore) return
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.sortKey === 'labelName' ? 1 : 2,
        mode: this.sortType === 'asc' ? 1 : 2,
        folderType: 22
      }

      params.folderId = this.groupId

      // if (this.groupId > 0) {
      //   params.folderId = this.groupId
      // } else {
      //   this.data = []
      //   $state.complete()
      //   return
      // }
      getTagList(params).then(res => {
        if (res.code === 0) {
          $state.loaded()
          let list = res.data.list.map(child => {
            child.labelName = child.name
            return child
          })
          if (list.length === 0) {
            this.isMore = false
            $state.complete()
            return
          }
          list.forEach(item => {
            item.isCheck = false
          })
          this.data = this.data.concat(list)
          if (res.data.pages === params.pageNum) {
            this.isMore = false
            $state.complete()
          } else {
            this.pageNum++
          }
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 点击新建标签
    onClickCreateTag () {
      this.createTagObj = {
        groupId: -1,
        name: '',
        color: '',
        bgc: ''
      }
      this.createTagArr = [this.createTagObj]
      this.tagPopupStatus = 1
      this.createTagVisible = true
    },
    // 点击添加新建标签
    onClickAddCreateTag () {
      this.createTagObj = {
        groupId: -1,
        name: '',
        color: '',
        bgc: ''
      }
      this.createTagArr.push(this.createTagObj)
    },
    // 点击删除新建标签
    onDeleteCreateTag (event, name) {
      this.createTagArr.splice(name, 1)
    },
    // 点击修改标签
    onClickEditTag (row) {
      this.createTagObj = {
        labelId: row.id,
        groupId: row.folderId,
        name: row.labelName,
        color: row.fontColor,
        bgc: row.color
      }
      this.createTagArr = [this.createTagObj]
      this.tagPopupStatus = 2
      this.createTagVisible = true
    },
    // 点击合并标签
    onClickMergeTag () {
      this.createTagObj = {
        groupId: -1,
        name: '',
        color: '',
        bgc: ''
      }
      this.createTagArr = [this.createTagObj]
      this.tagPopupStatus = 3
      this.createTagVisible = true
    },
    // 新建/编辑/合并标签
    onClickCreateConfirm () {
      // if (createTagObj.groupId < 0) return this.$Message.info('请选择标签分组')
      // if (!createTagObj.name) return this.$Message.info('请填写标签内容')
      // if (!createTagObj.color) return this.$Message.info('请选择标签文字颜色')
      // if (!createTagObj.bgc) return this.$Message.info('请选择标签颜色')
      let createTagObj = this.createTagObj
      if (this.tagPopupStatus === 1) {
        let params = this.createTagArr.map(item => {
          return {
            folderId: item.groupId,
            folderType: 22,
            name: item.name,
            color: item.bgc,
            fontColor: item.color
          }
        })
        createTag(params).then(res => {
          if (res.code === 0) {
            this.$Message.info('新建成功')
            this.createTagVisible = false
            this.getGroupList()
            this.resetListData()
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else if (this.tagPopupStatus === 2) {
        let params = {
          folderId: createTagObj.groupId,
          name: createTagObj.name,
          color: createTagObj.bgc,
          fontColor: createTagObj.color,
          labelId: createTagObj.labelId
        }
        editTag(createTagObj.labelId, params).then(res => {
          if (res.code === 0) {
            this.$Message.info('修改成功')
            this.createTagVisible = false
            this.getGroupList()
            this.resetListData()
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        let idList = this.selectArr.map(item => item.id)
        let params = {
          folderId: createTagObj.groupId,
          name: createTagObj.name,
          color: createTagObj.bgc,
          fontColor: createTagObj.color,
          labelIdList: idList,
          folderType: 22
        }
        mergeTag(params).then(res => {
          if (res.code === 0) {
            this.$Message.info('合并成功')
            this.createTagVisible = false
            this.getGroupList()
            this.resetListData()
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    // 点击删除
    onClickDelete (row) {
      this.onDelete([row])
    },
    // 点击批量删除
    batchDelete () {
      this.onDelete(this.selectArr)
    },
    // 删除标签
    onDelete (list) {
      this.$Modal.confirm({
        title: '删除',
        content: '<p>确定要删除标签</p>',
        onOk: () => {
          let idList = list.map(item => item.id)
          deleteTag({
            idList: idList.join()
          }).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功!')
              this.resetListData()
              this.getGroupList()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 点击改变分组
    onClickChangeTagGroup () {
      this.changeTagGroupVisible = true
      this.changeTagGroupId = 0
    },
    // 改变标签分组
    onClickChangeConfirm () {
      let idList = this.selectArr.map(item => item.labelId)
      let params = {
        groupId: this.changeTagGroupId,
        groupType: 1,
        objList: [{
          objType: 3,
          objIdList: idList
        }]
      }
      objRelevanceGroup(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('移动成功!')
          this.changeTagGroupVisible = false
          this.resetListData()
          this.getGroupList()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 选择改变
    onSelectionChange (selection) {
      this.selectArr = selection
    },
    // 排序
    onSortChange (obj) {
      this.sortKey = obj.key
      this.sortType = obj.order
      this.resetListData()
    },
    // 获取标签关联的文件夹和文件
    getTagFolderAndFileList (row) {
      this.tagId = row.id
      this.resetChildListData()
      this.childVisible = true
    },
    // 重置child列表数据
    resetChildListData () {
      this.childPageNum = 1
      this.childData = []
      this.selectChildArr = []
      this.isMoreFolder = true
      this.isMoreFile = true
      this.manualResetChildState = !this.manualResetChildState
    },
    // 获取child列表
    getAllFileList ($state) {
      if (this.isMoreFolder) {
        this.getTagFolderList($state)
      } else if (this.isMoreFile) {
        this.getTagFileList($state)
      } else {
        $state.complete()
      }
    },
    // 获取文件夹列表
    getTagFolderList ($state) {
      let params = {
        pageNum: this.childPageNum,
        pageSize: this.childPageSize,
        sort: this.childSortKey === 'editTime' ? 2 : 1,
        mode: this.childSortType === 'asc' ? 1 : 2,
        labelId: this.tagId,
        folderType: 1
      }
      getTagFolderList(params).then(res => {
        if (res.code === 0) {
          $state.loaded()
          let list = res.data.list
          if (list.length === 0) {
            this.isMoreFolder = false
            this.childPageNum = 1
            return
          }
          list.forEach(item => {
            item.objType = 2
            item.newName = item.name || ''
            item.editTime = item.updateTime || ''
            item.isRename = false
            item.isCheck = false
            item.isSign = item.isHasLabel
            let uploadUser = item.uploadUser
            item.userName = uploadUser ? uploadUser.name || '' : ''
          })
          this.childData = this.childData.concat(list)
          if (res.data.pages === params.pageNum) {
            this.isMoreFolder = false
            this.childPageNum = 1
          } else {
            this.childPageNum++
          }
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 获取文件列表
    getTagFileList ($state) {
      let params = {
        pageNum: this.childPageNum,
        pageSize: this.childPageSize,
        sort: this.childSortKey === 'name' ? 1 : this.childSortKey === 'editTime' ? 2 : 3,
        mode: this.childSortType === 'asc' ? 1 : 2,
        labelId: this.tagId,
        folderType: 1
      }
      getTagFileList(params).then(res => {
        if (res.code === 0) {
          $state.loaded()
          let list = res.data.list
          if (list.length === 0) {
            this.isMoreFile = false
            $state.complete()
            return
          }
          list.forEach(item => {
            let content = item.content
            item.name = content.displayName || content.name || ''
            item.editTime = item.uploadTime || ''
            item.size = content.size || 0
            item.objType = 1
            item.newName = content.displayName || content.name || ''
            item.url = content.url || ''
            item.isRename = false
            item.isCheck = false
            let uploadUser = item.uploadUser
            item.isSign = item.isHasLabel
            item.userName = uploadUser ? uploadUser.name || '' : ''
          })
          this.childData = this.childData.concat(list)
          if (res.data.pages === params.childPageNum) {
            this.isMoreFile = false
            $state.complete()
          } else {
            this.childPageNum++
          }
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // child排序
    onSortChildChange (obj) {
      this.sortKey = obj.key
      this.sortType = obj.order
      this.resetListData()
    },
    // child选择改变
    onChildSelectionChange (selection) {
      this.selectChildArr = selection
    },
    // child双击某一行
    onRowDblclick (row) {
      if (row.objType === 1) {
        let url = 'http://47.104.70.223:8012/onlinePreview?url=' + encodeURIComponent(this.$BaseSix.encode(row.url))
        window.open(url)
      } else if (row.objType === 2) {
        let params = {
          id: row.id,
          name: row.name,
          parentId: row.parentId
        }
        this.$router.push({
          path: '/disk/' + this.$Base64.encode(JSON.stringify(params))
        })
      }
    },
    // child操作
    onClickOperation (key, row, index) {
      let item = this.data[index]
      switch (key) {
        case 'move':
          this.onClickMove(row)
          break
        case 'rename':
          this.$refs.table.onClickRename(item, index)
          break
        case 'delete':
          this.onClickDelete(row)
          break
        case 'authority':
          break
      }
    },
    // 点击child重命名
    onClickChildRename () {
      let selectItem = this.selectArr[0]
      let item
      let index
      let objType = selectItem.objType
      for (const i in this.data) {
        let dataItem = this.data[i]
        if (objType === 1) {
          if (selectItem.fileId === dataItem.fileId) {
            item = dataItem
            index = i
            break
          }
        } else {
          if (selectItem.folderId === dataItem.folderId) {
            item = dataItem
            index = i
            break
          }
        }
      }
      let comp = this.listShowType === 1 ? 'table' : 'grid'
      this.$refs[comp].onClickRename(item, index)
    },
    // child重命名
    onChildRename (item) {
      if (item.objType === 1) {
        let params = {
          fileId: item.fileId,
          fileName: item.newName
        }
        editFileName(params).then(res => {
          item.isRename = false
          if (res.code === 0) {
            item.name = res.data.name
          } else {
            item.newName = item.name
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        let params = {
          folderId: item.folderId,
          folderName: item.newName
        }
        editFolderName(params).then(res => {
          item.isRename = false
          if (res.code === 0) {
            item.name = res.data.name
          } else {
            item.newName = item.name
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    // 点击删除child
    onClickDeleteChild (row) {
      this.onDeleteChild([row])
    },
    // 点击批量删除child
    batchDeleteChild () {
      this.onDeleteChild(this.selectChildArr)
    },
    // 批量删除文件夹与文件进回收站
    onDeleteChild (list) {
      this.$Modal.confirm({
        title: '删除',
        content: '<p>删除后将移入回收站</p>',
        onOk: () => {
          let folderList = []
          let fileList = []
          list.forEach(item => {
            if (item.objType === 1) {
              fileList.push({
                fileId: item.fileId,
                fileName: item.name,
                originalPath: item.absolutePath,
                size: item.size
              })
            } else {
              folderList.push({
                folderId: item.folderId,
                folderName: item.name,
                originalPath: item.absolutePath
              })
            }
          })
          deleteFolderAndFileToRecycle({
            folderList, fileList
          }).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功!')
              this.resetListData()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 获取选择的文件夹
    getSelectFolder (obj) {
      this.$emit('getMoveSelectFolder', obj)
    },
    // 取消选择文件夹
    cancleSelectFolder () {
      this.$off('getMoveSelectFolder')
    },
    // 点击移动child
    onClickMoveChild (row) {
      this.directoryVisible = true
      this.$once('getMoveSelectFolder', (query) => {
        this.onMove(query, [row])
      })
    },
    // 点击批量移动child
    batchMoveChild () {
      this.directoryVisible = true
      this.$once('getMoveSelectFolder', (query) => {
        this.onMoveChild(query, this.selectArr)
      })
    },
    // 批量移动文件夹与文件
    onMoveChild (query, arr) {
      let folderList = []
      let fileList = []
      arr.forEach(item => {
        if (item.objType === 1) {
          fileList.push({
            fileId: item.fileId,
            fileName: item.name
          })
        } else {
          folderList.push({
            folderId: item.folderId,
            folderName: item.name
          })
        }
      })
      let params = {
        folderId: query.folderId,
        folderList,
        fileList
      }
      moveFolderAndFile(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('移动成功!')
          this.resetListData()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
  .main {
    width: 100%;
    height: 100%;

    .topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-bottom: 1px solid #d8d8d8;

      .topbar-left {
        display: flex;
        align-items: center;

        .newFolder {
          width: 130px;
          height: 34px;
        }

        .batchBtn {
          width: 100px;
          height: 34px;
        }
      }

      .topbar-right {
        display: flex;
        align-items: center;

        .search {
          display: flex;
          align-items: center;
          width: 360px;
          height: 34px;
          padding: 0 15px;
          background-color: #ededed;
          border-radius: 17px;

          input {
            font-size: 14px;
            flex: 1;
          }

          .i-icon {
            margin-left: 10px;
          }
        }
      }

      ::v-deep .ivu-btn {
        padding: 0;
      }
    }

    .content {
      height: 100%;
      display: flex;

      .list {
        padding: 20px;
        flex: 1;
        height: 100%;
        overflow-y: scroll;
        border-left: 1px solid #d8d8d8;

        .operation {
          display: flex;
          align-items: center;

          ::v-deep .ivu-dropdown-rel {
            display: flex;
            align-items: center;
          }

          ::v-deep .ivu-icon {
            cursor: pointer;
          }
        }
      }
    }

    ::v-deep .ivu-drawer {
      top: 64px;
    }

    .childView {
      padding: 4px;

      .childView-close {
        font-size: 14px;
        color: #2d8cf0;
        cursor: pointer;
      }

      .childView-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .childView-right {
          display: flex;
          align-items: center;

          .i-icon {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      .childView-con {
        padding: 30px;
      }
    }
  }

  .createTag {
    padding: 20px 64px;

    .createTag-title {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .createTag-form {
      .createTag-li {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        span {
          width: 70px;
          font-size: 14px;
        }

        .createTag-list {
          .createTag-input {
            height: 28px;
            font-size: 14px;
          }

          ::v-deep .ivu-icon-ios-close {
            color: #000 !important;
          }
        }
      }
    }

    .createTag-sub {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .createTag-btn {
        width: 80px;
      }
    }
  }

  .changeTagGroup {
    padding: 20px 30px;

    .changeTagGroup-title {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .changeTagGroup-form {
      .changeTagGroup-li {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        span {
          width: 70px;
          font-size: 14px;
        }
      }
    }

    .changeTagGroup-sub {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .changeTagGroup-btn {
        width: 80px;
      }
    }
  }
</style>
