<template>
  <Poptip v-model="visible"
          transfer
          width="212"
          placement="bottom"
          padding="15px">
    <slot></slot>
    <div class="poptip"
         slot="content">
      <p class="poptip-title">请输入分组名称</p>
      <Input v-model="name"
             maxlength="6"
             show-word-limit
             class="m-t-10"></Input>
      <div class="poptip-sub">
        <Button type="primary"
                class="poptip-btn"
                @click="onClickConfirm">确定</Button>
        <Button class="poptip-btn"
                @click.stop="visible=false">取消</Button>
      </div>
    </div>
  </Poptip>
</template>

<script>
export default {
  props: {},
  data () {
    return {
      name: '',
      visible: false
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    onClickConfirm () {
      if (!this.name) return this.$Message.info('分组名称不能为空')
      this.$emit('on-click-confirm', this.name)
    }
  },
}
</script>

<style lang='scss' scoped>
.poptip {
  .poptip-title {
    font-size: 16px;
  }
  .poptip-sub {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .poptip-btn {
      width: 80px;
    }
  }
}
</style>